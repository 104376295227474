<template>
    <div>
        <subHeader activeTheme="suedstrand204"/>
        <main>
            <b-container>
                <p class="backLink"><span class="icon-back"></span> <a href="/" title="Zurück zur Hauptseite" >Zurück zur Hauptseite</a></p>

                <h1>Informationen zum Datenschutz</h1>
                <p>Diese Datenschutzerklärung informiert Sie über unseren Umgang mit Ihren Daten. Damit die Verarbeitungen Ihrer Daten für Sie nachvollziehbar sind, möchten wir Ihnen mit den folgenden Informationen einen Überblick zu diesen Verarbeitungen verschaffen. Um eine faire Verarbeitung zu gewährleisten, beinhaltet diese Datenschutzerklärung allgemeinen Angaben zu unserem Umgang mit Ihren Daten sowie Informationen über Ihre Rechte nach der Europäischen Datenschutz-Grundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG).</p>
                <p>Wir informieren Sie außerdem im Detail über</p>
                <ul class="list" >
                    <li><NuxtLink to="#generalInformation">Allgemeine Angaben</NuxtLink></li>
                    <li><NuxtLink to="#dataHandling">Datenverarbeitungen auf unserer Website</NuxtLink></li>
                    <li><NuxtLink to="#moredataHandling">Weitere Datenverarbeitungen.</NuxtLink></li>
                </ul>
                <p>Verantwortlich für die Datenverarbeitung ist Ingeborg Schäfer. </p>
            </b-container>
            <b-container>
                <h2 id="generalInformation">I. Allgemeine Angaben</h2>
                <h4>1. Kontakt</h4>
                <p>Wenn Sie Fragen oder Anregungen zu diesen Informationen haben oder sich wegen der Geltendmachung Ihrer Rechte an uns wenden möchten, richten Sie Ihre Anfrage bitte an</p>
                <p>Ingeborg Schäfer<br>Edelhofdamm 6<br>13465 Berlin<br/>Tel: +49 (30) 401 63 84<br/>Fax: +49 (30) 401 02 113<br/>E-Mail: <a href="mailto:kontakt@suedstrand-wohnung-204.de">kontakt@suedstrand-wohnung-204.de</a></p>
                <h4>2. Rechtsgrundlagen</h4>
                <p>Wir verarbeiten personenbezogene Daten unter Beachtung der einschlägigen Datenschutzvorschriften, insbesondere der DSGVO und des BDSG. Eine Datenverarbeitung durch uns findet nur auf der Grundlage einer gesetzlichen Erlaubnis statt. Wir verarbeiten personenbezogene Daten nur mit Ihrer Einwilligung (Art. 6 Abs. 1 Buchst. a) DSGVO), zur Erfüllung eines Vertrags, dessen Vertragspartei Sie sind, oder auf Ihre Anfrage zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 Buchst. b) DSGVO), zur Erfüllung einer rechtlichen Verpflichtung (Art. 6 Abs.1 Buchst. c) DSGVO) oder wenn die Verarbeitung zu Wahrung unser berechtigten Interessen oder den berechtigten Interessen eines Dritten erforderlich ist, sofern nicht Ihre Interessen oder Grundrechte und Grundfreiheiten, die den Schutz personenbezogener Daten erfordern, überwiegen (Art. 6 Abs. 1 Buchst. f) DSGVO).</p>
                <h4>3. Dauer der Speicherung</h4>
                <p>Sofern sich aus den folgenden Hinweisen nichts anderes ergibt, speichern wir die Daten nur solange, wie es zur Erreichung des Verarbeitungszwecks oder für die Erfüllung unserer vertraglichen oder gesetzlichen Pflichten erforderlich ist. Solche gesetzlichen Aufbewahrungspflichten können sich insbesondere aus handels- oder steuerrechtlichen Vorschriften ergeben.</p>
                <h4>4. Empfänger der Daten</h4>
                <p>Wir bedienen uns beauftragter Dienstleister für einzelne Verarbeitungen. Dazu gehören z.B. Hosting, Wartung und Support von IT-Systemen, Marketingmaßnahmen oder Akten- und Datenträgervernichtung. Diese Dienstleister verarbeiten die Daten nur nach ausdrücklicher Weisung und sind vertraglich zur Gewährleistung geeigneter technischer und organisatorischer Maßnahmen zum Datenschutz verpflichtet. Im Übrigen übermitteln wir ggf. personenbezogene Daten unserer Kunden an Stellen wie Post- und Zustelldienste, Zahlungs- und Auskunftsdienste, Hausbank, Steuerberater/Wirtschaftsprüfer oder die Finanzverwaltung.</p>
                <h4>5. Verarbeitung bei der Ausübung Ihrer Rechte gem. Art. 15 bis 22 DSGVO</h4>
                <p>Wenn Sie Ihre Rechte gemäß den Art. 12 bis 22 DSGVO ausüben, verarbeiten wir die übermittelten personenbezogenen Daten zum Zweck der Umsetzung dieser Rechte durch uns und um den Nachweis hierüber erbringen zu können. Zum Zweck der Auskunftserteilung und deren Vorbereitung gespeicherte Daten werden wir nur für diesen Zweck sowie für Zwecke der Datenschutzkontrolle verarbeiten und im Übrigen die Verarbeitung nach Maßgabe des Art. 18 DSGVO einschränken.<br>Diese Verarbeitungen beruhen auf der Rechtsgrundlage des Art. 6 Abs. 1 Buchst. c) DSGVO i.V.m. Art. 15 bis 22 DSGVO und § 34 Abs. 2 BDSG.</p>
                <h4>6. Ihre Rechte</h4>
                <p>Als betroffene Person haben Sie das Recht, uns gegenüber Ihre Betroffenenrechte geltend zu machen. Dabei haben Sie insbesondere die folgenden Rechte:</p>
                <ul class="list">
                    <li>Sie haben nach Maßgabe des Art. 15 DSGVO und § 34 BDSG das Recht, Auskunft darüber zu verlangen, ob und gegebenenfalls in welchen Umfang wir personenbezogene Daten zu Ihrer Person verarbeiten oder nicht.</li>
                    <li>Sie haben das Recht, nach Maßgabe des Art. 16 DSGVO von uns die Berichtigung Ihrer Daten zu verlangen.</li>
                    <li>Sie haben das Recht, nach Maßgabe des Art. 17 DSGVO und § 35 BDSG von uns die Löschung Ihrer personenbezogenen Daten zu verlangen.</li>
                    <li>Sie haben das Recht, nach Maßgabe des Art. 18 DSGVO die Verarbeitung Ihrer personenbezogenen Daten einschränken zu lassen.</li>
                    <li>Sie haben das Recht, nach Maßgabe des Art. 20 DSGVO die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und diese Daten einem anderen Verantwortlichen zu übermitteln.</li>
                    <li>Sofern Sie uns eine gesonderte Einwilligung in die Datenverarbeitung erteilt haben, können Sie diese Einwilligung nach Maßgabe des Art. 7 Abs. 3 DSGVO jederzeit widerrufen. Durch einen solchen Widerruf wird die Rechtmäßigkeit der Verarbeitung, die bis zum Widerruf aufgrund der Einwilligung erfolgt ist, nicht berührt.</li>
                    <li>Wenn Sie der Ansicht sind, dass eine Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Bestimmungen der DSGVO verstößt, haben Sie nach Maßgabe des Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde.</li>
                </ul>
                <h4>7. Widerspruchsrecht</h4>
                <p>Sie haben nach Maßgabe des Art. 21 Abs. 1 DSGVO das Recht, gegen Verarbeitungen, die auf der Rechtsgrundlage des Art. 6 Abs. 1 Buchst. e) oder f) DSGVO beruht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, Widerspruch einzulegen. Sofern durch uns personenbezogene Daten über Sie zum Zweck der Direktwerbung verarbeitet werden, können Sie gegen diese Verarbeitung gem. Art. 21 Abs. 2 und Abs. 3 DSGVO Widerspruch einlegen.</p>
            </b-container>
            <b-container>
                <h2 id="dataHandling">II. Datenverarbeitungen auf unserer Website</h2>
                <p>Bei der Nutzung der Website erfassen wir Informationen, die Sie selbst bereitstellen. Außerdem werden während Ihres Besuchs auf der Website automatisch bestimmte Informationen über Ihre Nutzung der Website durch uns erfasst. Im Datenschutzrecht gilt grundsätzlich auch die IP-Adresse als ein personenbezogenes Datum. Eine IP-Adresse wird jedem mit dem Internet verbundenen Gerät durch den Internetprovider zugewiesen, damit es Daten senden und empfangen kann.</p>
                <h4>1. Verarbeitung von Server-Log-Files</h4>
                <p>Bei der rein informativen Nutzung unserer Website werden zunächst automatisiert (also nicht über eine Registrierung) allgemeine Informationen gespeichert, die Ihr Browser an unseren Server übermittelt. Hierzu zählen standardmäßig: Browsertyp/ -version, verwendetes Betriebssystem, aufgerufene Seite, die zuvor besuchte Seite (Referrer URL), IP-Adresse, Datum und Uhrzeit der Serveranfrage und HTTP-Statuscode. Die Verarbeitung erfolgt zur Wahrung unserer berechtigten Interessen und beruht auf der Rechtsgrundlage des Art. 6 Abs. 1 Buchst. f) DSGVO. Diese Verarbeitung dient der technischen Verwaltung und der Sicherheit der Website. Die gespeicherten Daten werden nach maximal 30 Tagen gelöscht, wenn nicht aufgrund konkreter Anhaltspunkte ein berechtigter Verdacht auf eine rechtswidrige Nutzung besteht und eine weitere Prüfung und Verarbeitung der Informationen aus diesem Grund erforderlich ist. Wir sind nicht in der Lage, Sie anhand der gespeicherten Informationen als betroffene Person zu identifizieren. Die Art. 15 bis 22 DSGVO finden daher gem. Art. 11 Abs. 2 DSGVO keine Anwendung, es sei denn, Sie stellen zur Ausübung ihrer in diesen Artikeln niedergelegten Rechte zusätzliche Informationen bereit, die ihre Identifizierung ermöglichen</p>
                <h4>2. Buchungsanfragen und Kontakt per E-Mail oder Telefon</h4>
                <p>Wenn Sie uns eine über das Formular auf unserer Internetseite eine Buchungsanfrage, über die angegebene Kontakt-E-Mail eine Nachricht schicken oder sich telefonisch an uns wenden, werden wir die übermittelte Nachricht und die E-Mail-Adresse zu dem Zweck verarbeiten, Ihre Anfrage zu beantworten.</p>
                <p>Sofern sich Ihre Anfrage auf den Abschluss oder die Durchführung eines Vertrages mit uns richtet, ist Art. 6 Abs. 1 Buchst. b) DSGVO Rechtsgrundlage für die Datenverarbeitung. Ansonsten verarbeiten wir die Daten aufgrund unseres berechtigten Interesses, mit anfragenden Personen in Kontakt zu treten. Rechtsgrundlage für die Datenverarbeitung ist dann Art. 6 Abs. 1 Buchst. f) DSGVO.</p>
                <h4>3. Cookies</h4>
                <p>Wir verwenden auf unserer Website Cookies. Bei Cookies handelt es sich um kleine Textdateien, die durch Ihren Browser gespeichert werden, wenn Sie eine Website besuchen. Hierdurch wird der verwendete Browser gekennzeichnet und kann durch unseren Webserver wiedererkannt werden. Sofern es durch diese Verwendung von Cookies zu einer Verarbeitung personenbezogener Daten kommt, beruht diese auf der Rechtsgrundlage des Art. 6 Abs. 1 Buchst. f) DSGVO. Diese Verarbeitung dient unserem berechtigten Interesse, unsere Website nutzerfreundlicher, effektiver und sicherer zu machen. Wir verwendeten sogenannte „Session-Cookies“, die beim Beenden der Browsersitzung wieder gelöscht werden. Andere Cookies („Persistent Cookies“) werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen. Sie können der Verwendung von Cookies durch Ihre Browsereinstellungen grundsätzlich oder für bestimmte Fälle widersprechen.<br/>Weitere Informationen hierzu bietet Ihnen das Bundesamt für Sicherheit in der Informationstechnik unter <a href="<a href="https://www.bsi-fuer-buerger.de" target="blank">https://www.bsi-fuer-buerger.de</a>" target="blank"><a href="https://www.bsi-fuer-buerger.de" target="blank">https://www.bsi-fuer-buerger.de</a></a>.
                </p>
            </b-container>
            <b-container>
                <h2 id="moredataHandling">III. Weitere Datenverarbeitungen</h2>
                <h4>1. Vertragsbeziehungen</h4>
                <p>Zur Begründung oder Durchführung der Vertragsbeziehung zu unseren Kunden und Lieferanten ist regelmäßig die Verarbeitung der uns bereitgestellten personenbezogenen Stamm-, Vertrags- und Zahlungsdaten erforderlich. Rechtgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 Buchst. b) DSGVO. Außerdem verarbeiten wir Kunden- und Interessentendaten zu Auswertungs- und zu Marketingzwecken. Diese Verarbeitungen erfolgen auf der Rechtgrundlage des Art. 6 Abs. 1 Buchst. f) DSGVO und dienen unserem Interesse, unser Angebot weiterzuentwickeln und Sie gezielt über Angebote der ONDIGO GmbH & Co. KG zu informieren. Weitere Datenverarbeitungen können erfolgen, wenn Sie eingewilligt haben (Art. 6 Abs 1 Buchst. a) DSGVO) oder wenn dies der Erfüllung einer rechtlichen Verpflichtung dient (Art. 6 Abs. 1 Buchst. c) DSGVO).</p>
            </b-container>
        </main>
        <fewofooter activeTheme="suedstrand204"/>
    </div>
</template>

<script>
    import subHeader from "~/components/page/header/subheader.vue";
    import fewofooter from "~/components/page/footer/main.vue";

    export default {
        components: {
            subHeader,
            fewofooter,
        },

        head() {
            return {
                title: "Ferienwohung auf de Insel Fehmarn",
                meta: [
                    { name: "description", content: "Die Ferienwohnung für 1-4 Personen liegt im Ort Burgtiefe auf der Insel Fehmarn, 5 Min. vom Südstrand entfernt." },
                    { name: "msapplication-TileColor", content: "#ffffff" },
                    { name: "msapplication-TileImage", content: "Ferienwohung auf de Insel Fehmarn" },
                    { name: "theme-color", content: "#ffffff" }
                ]
            }
        }
    }
</script>

<template>
    <b-container>
        <h1>Willkommen</h1>
        <b-row>
            <b-col cols="12" sm="6">
                <p>Im Süden der Insel Fehmarn im Ort Burgtiefe, liegt die Ferienwohnung mit Blick auf die Ostsee. Von dort aus erreichen Sie den beliebten Südstrand in nur 5 Minuten zu Fuß.</p>
                <p>In nächster Umgebung finden Sie das Erlebnisbad FehMare, das gemütliche Café Sorgenfrei und die Promenade, über die Sie einen kleinen Supermarkt sowie den Bäcker und mehrere Restaurants erreichen können.</p>
                <p>Wir freuen uns, Sie als unsere Gäste Willkommen zu heißen.</p>
                <p>Ihre Familie Schäfer</p>
            </b-col>
            <b-col cols="12" sm="6">
                <picture>
                    <source media="(min-width: 768px)" :data-srcset="require('~/assets/img/suedstrandwohnung204/sued_postcard.jpg?size=405').src + ' 1x,' + require('~/assets/img/suedstrandwohnung204/sued_postcard.jpg?size=810').src + ' 2x'"/>
                    <source media="(min-width: 320px)" :data-srcset="require('~/assets/img/suedstrandwohnung204/sued_postcard.jpg?size=768').src + ' 1x, ' + require('~/assets/img/suedstrandwohnung204/sued_postcard.jpg?resize').src + ' 2x'"/>
                    <img class="lazyload" :src="require('~/assets/img/suedstrandwohnung204/sued_postcard.jpg?resize').placeholder" :data-src="require('~/assets/img/suedstrandwohnung204/sued_postcard.jpg?size=405').src" alt="Südstrand Ferienwohnung 204"/>
                </picture>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Willkommen",
    }
</script>

<style scoped lang="scss">
    picture img {
        width: 100%;
    }
</style>
<template>
    <div id="fewo">
        <fewoheader activeTheme="suedstrand204" activeFewo="Südstrand Ferienwohnung 204"/>
        <main>
            <willkommen/>
            <fewos activeTheme="suedstrand204" headline="Ferienwohnung"/>
            <prices activeTheme="suedstrand204" headline="Preise"/>
            <location activeTheme="suedstrand204"/>
            <contact activeTheme="suedstrand204" activeFewo="Südstrand Ferienwohnung 204"/>
        </main>
        <fewofooter activeTheme="suedstrand204"/>
    </div>
</template>

<script>
    import fewoheader from "~/components/page/header/main.vue";
    import fewofooter from "~/components/page/footer/main.vue";
    import willkommen from "../custom-components/willkommen.vue";
    import fewos from "~/components/content/fewos.vue";
    import prices from "~/components/content/prices.vue";
    import location from "~/components/content/location.vue";
    import contact from "~/components/content/contact.vue";

    export default {
        components: {
            fewoheader,
            fewofooter,
            willkommen,
            fewos,
            prices,
            location,
            contact
        },

        head() {
            return {
                title: "Ferienwohung auf der Insel Fehmarn",
                meta: [
                    { name: "description", content: "Die Ferienwohnung für 1-4 Personen liegt im Ort Burgtiefe auf der Insel Fehmarn, 5 Min. vom Südstrand entfernt." },
                    { name: "msapplication-TileColor", content: "#ffffff" },
                    { name: "msapplication-TileImage", content: "Ferienwohung auf der Insel Fehmarn" },
                    { name: "theme-color", content: "#ffffff" }
                ]
            }
        }
    }
</script>
